import React from 'react'
import styled from 'styled-components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Button } from 'components/section/form/components/button'
import { TextareaControl } from 'components/section/form/group/textareaControl'
import { TextControl } from 'components/section/form/group/textControl'
import { NameControlGroup } from 'components/section/form/group/nameControlGroup'
import { SelectControl } from 'components/section/form/group/selectControl'
import { FormInputs } from 'types/form'
import errorMassages from 'utils/errorMessages'
import years from 'utils/selectItem'
import { siteStructure } from 'utils/site'
import { rem, innerWidth, breakpoints, innerPadding, innerDummyPadding, color } from 'variables/_index'

export interface PamphletFormSectionProps {
  readonly onSubmit: SubmitHandler<FormInputs>
  readonly userAgent: string
}

type PamphletFormSectionPropsClassName = PamphletFormSectionProps & {
  readonly className: string
}

function Component({ className, onSubmit, userAgent }: PamphletFormSectionPropsClassName) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onBlur',
  })

  return (
    <div className={className}>
      <div className="e-inner">
        <form className="e-form" name="pamphlet" method="POST" data-netlify="true" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="form-name" value="pamphlet" />
          <input type="hidden" name="formType" value="pamphlet" />
          <div className="e-body">
            <div className="e-form-items">
              <div className="e-form-item">
                <NameControlGroup
                  headTitle="お子さまのお名前（ふりがな）"
                  isRequire={true}
                  errorMessageAll={
                    errors.firstName?.type === 'required' || errors.lastName?.type === 'required'
                      ? errorMassages.required
                      : errors.lastName?.type === 'pattern'
                      ? errorMassages.kana
                      : errors.firstName?.type === 'pattern'
                      ? errorMassages.kana
                      : ''
                  }
                  errorMessageFirstName={
                    errors.firstName?.type === 'required' ? errorMassages.required : errors.firstName?.type === 'pattern' ? errorMassages.kana : ''
                  }
                  errorMessageLastName={
                    errors.lastName?.type === 'required' ? errorMassages.required : errors.lastName?.type === 'pattern' ? errorMassages.kana : ''
                  }
                  nameControls={{
                    firstName: {
                      headTitle: 'めい',
                      id: 'your-first-name',
                      type: 'text',
                      placeholder: 'たろう',
                      isError: errors.firstName ? true : false,
                      register: register,
                      name: 'firstName',
                      option: {
                        required: true,
                        pattern: /^[\u3041-\u309F｜\u30FC]+$/,
                      },
                    },
                    lastName: {
                      headTitle: 'せい',
                      id: 'your-last-name',
                      type: 'text',
                      placeholder: 'やまだ',
                      isError: errors.lastName ? true : false,
                      register: register,
                      name: 'lastName',
                      option: {
                        required: true,
                        pattern: /^[\u3041-\u309F｜\u30FC]+$/,
                      },
                    },
                  }}
                />
              </div>
              <div className="e-form-item">
                <SelectControl
                  headTitle="現在の学年"
                  id="your-year"
                  name="year"
                  register={register}
                  errorMessage={errors.year ? errorMassages.required : ''}
                  isError={errors.year ? true : false}
                  selectItems={years}
                  option={{ required: true }}
                />
              </div>
              <div className="e-form-item">
                <TextControl
                  headTitle="EーMail（半角英数）"
                  id="your-email"
                  type="email"
                  placeholder="abc@fit-net.com"
                  register={register}
                  errorMessage={
                    errors.email?.type === 'required' ? errorMassages.required : errors.email?.type === 'pattern' ? errorMassages.pattern : ''
                  }
                  name="email"
                  isError={errors.email ? true : false}
                  option={{
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  }}
                />
              </div>
              <div className="e-form-item">
                <TextControl
                  headTitle="電話番号（半角数字）"
                  id="your-tel"
                  type="text"
                  placeholder="09012345678"
                  register={register}
                  errorMessage={
                    errors.tel?.type === 'required' ? errorMassages.required : errors.tel?.type === 'pattern' ? errorMassages.pattern : ''
                  }
                  name="tel"
                  isError={errors.tel ? true : false}
                  option={{ required: true, pattern: /^0[-0-9]{9,12}$/i }}
                />
              </div>
              <div className="e-form-item">
                <TextareaControl
                  headTitle="その他"
                  id="your-message"
                  placeholder="ご自由にご記入ください"
                  name="message"
                  register={register}
                  isError={errors.message ? true : false}
                  errorMessage={errors.message ? errorMassages.required : ''}
                  option={{ required: false }}
                />
              </div>
            </div>
          </div>
          <div className="e-footer">
            <div className="e-privacy-area">
              送信いただく前に<span className="is-palt">「</span>
              <a className="e-privacy-link" href={siteStructure.privacypolicy.path} target="_blank" rel="noopener noreferrer">
                個人情報保護方針
              </a>
              <span className="is-palt">」</span>
              をご一読いただき、ご同意いただく必要がございます。
            </div>
            <div className="e-button-lead">個人情報保護方針について同意の上</div>
            <div className="e-button-area">
              <Button text="資料請求する" />
            </div>
          </div>
          <input type="hidden" name="userAgent" value={userAgent} />
        </form>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<PamphletFormSectionPropsClassName>`
  padding-bottom: ${rem(80 / 2)};

  @media ${breakpoints.lg} {
    padding-bottom: ${rem(200)};
  }

  > .e-inner {
    position: relative;
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;

    @media ${breakpoints.md} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-form {
      margin: 0;

      > .e-body {
        > .e-form-items {
          background-color: #fff;

          > .e-form-item {
            padding-top: ${rem(40 / 2)};
            padding-bottom: ${rem(60 / 2)};
            border-top: 1px solid ${color.main};

            @media ${breakpoints.md} {
              padding-top: ${rem(30)};
              padding-bottom: ${rem(30)};
            }

            &:last-child {
              border-bottom: 1px solid ${color.main};
            }
          }
        }
      }

      > .e-footer {
        margin-top: ${rem(10)};

        @media ${breakpoints.md} {
          margin-top: ${rem(10)};
        }

        > .e-privacy-area {
          font-size: ${rem(14)};

          @media ${breakpoints.md} {
            font-size: ${rem(16)};
          }

          > .e-privacy-link {
            color: ${color.accent2};
            font-size: inherit;
            transition: opacity 0.3s ease;

            &:hover {
              opacity: 0.8;
            }
          }

          .is-palt {
            font-feature-settings: 'palt';
          }
        }

        > .e-button-lead {
          margin-top: ${rem(50)};
          text-align: center;
          color: ${color.main};
          font-size: ${rem(16)};
          font-weight: 500;

          @media ${breakpoints.md} {
            margin-top: ${rem(80)};
            font-size: ${rem(18)};
          }
        }

        > .e-button-area {
          margin-top: ${rem(10)};
          text-align: center;

          @media ${breakpoints.md} {
            margin-top: ${rem(10)};
          }

          > .e-button {
          }
        }
      }
    }
  }
`

export const PamphletFormSection: (props: PamphletFormSectionProps) => JSX.Element = StyledComponent

export default PamphletFormSection
